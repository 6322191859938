<template>
	<div class="symposium-box">
		<!-- banner -->
		<div class="banner">
			<img src="@/assets/img/sym_banner.jpg" alt="">
		</div>
		<!-- content -->
		<div class="sym-content">
			<div class="sym-title">
			<p>中国共产党第二十次全国代表大会在京开幕</p>	
			<p>习近平代表第十九届中央委员会向大会作报告</p>	
			</div>
			<div class="sym-small-title">
				党的二十大举行预备会议&nbsp;习近平主持会议
			</div>
			<div class="swiper-type">
				<div class="sys-swiper-box">
					<el-carousel @change="swiperChange" indicator-position="none" height="468px">
                        <el-carousel-item class="swiper-item" v-for="item in swiperList" :key="item.newsId">
                            <img :src="item.titleImage" @click="goDatailPage(item.newsId,item.typeId)">
                            <div class="swiperImg-button"></div>
                            <p>{{item.newsTitle}}</p>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="swiper-indicator">{{swiperCurrent}}/{{swiperList.length}}</div>
				</div>
                <div class="type-1">
					<div class="type-item  zyjs">
                       <div class="title">
							<div class="title-left">{{zyjsList.typeName}}</div>
							<div class="title-more" @click="moreFn(zyjsList.typeId)">更多+</div>
					   </div>
                       <div class="type-list" v-for="item in zyjsList.newsList" :key="item.newsId" 
					   @click="goDatailPage(item.newsId,zyjsList.typeId)">
						<div class="type-qiu"></div>
						<p>{{item.newsTitle}}</p>
					   </div>
					</div>
					<div class="type-item ywbd">
                        <div class="title">
							<div class="title-left">{{ywbdList.typeName}}</div>
							<div class="title-more" @click="moreFn(ywbdList.typeId)">更多+</div>
					   </div>
					   <div class="type-list" v-for="item in ywbdList.newsList" :key="item.newsId"
					   @click="goDatailPage(item.newsId,ywbdList.typeId)">
						<div class="type-qiu"></div>
						<p>{{item.newsTitle}}</p>
					   </div>
					</div>
				</div>
			</div>
			<div class="type-2">
					<div class="type-item  qsesd">
						<div class="title">
							<div class="title-left">{{qsesdList.typeName}}</div>
							<div class="title-more" @click="moreFn(qsesdList.typeId)">更多+</div>
					   </div>
					   <div class="type-list" v-for="item in qsesdList.newsList" :key="item.newsId"
					   @click="goDatailPage(item.newsId,qsesdList.typeId)">
						<div class="type-qiu"></div>
						<p>{{item.newsTitle}}</p>
					   </div>
					</div>
					<div class="type-item hyfx">
						<div class="title">
							<div class="title-left">{{hyfxList.typeName}}</div>
							<div class="title-more" @click="moreFn(hyfxList.typeId)">更多+</div>
					   </div>
					   <div class="type-list" v-for="item in hyfxList.newsList" :key="item.newsId"
					   @click="goDatailPage(item.newsId,hyfxList.typeId)">
						<div class="type-qiu"></div>
						<p>{{item.newsTitle}}</p>
					   </div>
					</div>
			</div>
		</div>
		<div class="sys-bottom">
			<p>版权所有：中国侨商联合会</p>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			this.getSymListFn()
			this.indexSymposium() // 获取专题

		},
		data() {
			return {
				zyjsList: [], // 中央精神
				ywbdList: [], // 要闻报道
				qsesdList: [], // 侨说二十大
				hyfxList: [], // 会员反响
				swiperCurrent: 0, // 当前的swiperItem
				swiperList:[] // 专题数据
			}
		},
		methods: {
			getSymListFn() {
				var params = {
					symposiumId: '20th'
				}
				this.$http.getSymList(params).then(res => {
					// console.log(res.data);
					this.zyjsList = res.data[0];
					this.ywbdList = res.data[1];
					this.qsesdList = res.data[2];
					this.hyfxList = res.data[3];
				})
			},
			swiperChange(e){
                this.swiperCurrent = e + 1 
			},
			// 获取专题
			indexSymposium(){
				var parmas = {
					symposiumId:'20th'
				}
				this.$http.symposiumeList(parmas).then(res =>{
					this.swiperList = res.data
				})
			},
			  // 详情跳转  1
			  goDatailPage(id, typeId) {
                this.$router.push({path: '/information/detail', query: {id: id, typeId: typeId}})
            },
			moreFn(typeId){
				this.$router.push({
                    path: '/information',
					query:{
						typeId:typeId
					}
                })
			}

		}
	}
</script>
<style scoped>
	.symposium-box .banner img {
		width: 100%;

	}
	.sym-content {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 100px;
	}
	.sym-content .sym-title {
		width: 878px;
		margin: 45px auto;
		color: #c3231c;
		font-size: 42px;
		font-weight: bold;
		text-align: center;
	}
	.sym-content .sym-small-title {
		text-align: center;
		color: #333333;
		font-size: 18px;
	}
	.sym-content .swiper-type{
		display: flex;
		margin-top: 40px;
	}
	.sym-content .swiper-type .sys-swiper-box{
		width: 724px;
	    height: 468px;
		position: relative;
	}
	.sym-content .swiper-item img{
		width: 100%;
		height: 100%;
		display: block;
	}
	.sym-content .swiper-item p{
		position: absolute;
		bottom: 20px;
		left: 15px;
		color: #fff;
		font-size: 18px;
		width: 520px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.sym-content .swiperImg-button{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 60px;
		background-image: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.7));
	}
	/* 分页器 */
	.swiper-indicator{
		position: absolute;
		right: 20px;
		bottom: 20px;
		font-size: 18px;
		font-weight: bolder;
		color: #fff;
		z-index: 9;
	}
	/* 轮播图的左按钮 */
	.el-carousel__arrow--left {
		left: 0;
	}
	/* 轮播图的右按钮 */
	.el-carousel__arrow--right{
		right: 0;
	}
	/* 按钮的大小 */
	.el-carousel__arrow{
		width: 26px;
		height: 56px;
		font-size: 18px;
		border-radius: 0;
	}
	.sym-content .type-1{
		margin-left: 20px;
	}
	.sym-content .type-item{
		box-sizing: border-box;
		padding: 20px;
		background-color: #f7f7f7;   
	}
	.sym-content .type-item.zyjs{
			margin-bottom: 20px;
	}
	.sym-content .type-item.qsesd{
		margin-right: 40px;
	}
	.sym-content .type-2{
		display: flex;
		margin-top: 40px;
	}
	.sym-content .type-item.zyjs,.type-item.ywbd {
		width: 456px;
		height: 224px;
	} 

	.sym-content .type-item.qsesd,.sym-content .type-item.hyfx{
		width: 580px;
	    height: 354px;
	}
    .sym-content .type-item .title{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 25px;
	}
	.sym-content .type-item .title .title-left{
		color: #c3231c;
		font-size: 26px;
	}
	.sym-content .type-item .title .title-more{
		color: #999999;
		font-size: 16px;
		cursor: pointer;
	}
	.sym-content .type-item .type-list{
		display: flex;
		align-items: center;
		margin-bottom: 26px;
	}
	.sym-content .type-item .type-list .type-qiu{
		flex-shrink: 0;
		width: 6px;
		height: 6px;
		background-color: #c3231c;
		margin-right: 5px;
		border-radius: 6px;
	}
	.sym-content .type-item .type-list p{
		color: #000000;
		font-size: 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}
    .sys-bottom{
	width: 100%;
	height: 200px;
	background-color: #cb1724;
	text-align: center;
	box-sizing: border-box;
	padding-top: 68px;
   }
   .sys-bottom p{
	color: #ffffff;
	font-size: 20px;
   }
</style>
